@import '../variables';

.react-tabs {
    width: 80%;
    margin: $mg-l auto $mg-s;

    .react-tabs__tab-list {
        display: flex;
        justify-content: space-around;
        border-bottom: 2px solid $clr-red-dark;
    }

    .react-tabs__tab {
        border-bottom: none;
        bottom: -2px;
        padding-left: $pd-m;
        padding-right: $pd-m;
    }

    .react-tabs__tab--selected {
        background-color: $clr-pink-light;
        font-weight: bold;
        border: 2px solid $clr-red-dark;
        border-bottom: none;
    }

    .video-player {
        margin: auto auto $mg-m;
    }
}

@media only screen and (max-width: 1200px) {
    .react-tabs__tab {
        padding: $pd-s 0;
        font-size: 80%;
    }
}

@media only screen and (max-width: 900px) {
    .react-tabs {
        width: 100%;
    }

    .react-tabs__tab-list{

    }

    .react-tabs__tab {
        padding: $pd-s 0;
        font-size: 70%;
    }

    .video-player {
        width: 100% !important;
        height: auto !important;
    }
}
