@import '../variables';

#socials {
    @extend %flex-col-center;

    p {
        margin-top: $mg-m;
        text-align: center;
    }

    .social-icons {
        @extend %flex-row-center;
        justify-content: center;

        a {
            color: $clr-red-dark;
            margin: $mg-m $mg-l $mg-l;
            font-size: 200%;

            &:hover {
                color: $clr-red-ruby;
            }

            svg {
                width: auto;
                height: 35px;
                margin-top: -5px;
                fill: $clr-red-dark;

                g {
                    stroke: $clr-red-dark;
                    stroke-width: 100px;
                }

                &:hover {
                    fill: $clr-red-ruby;

                    g {
                        stroke: $clr-red-ruby;
                        stroke-width: 100px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}
