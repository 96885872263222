@import '../variables';

.divider {
    @extend %flex-row-center;
    width: 100%;
    margin: 3*$mg-m 0;

    .divider-line {
        width: 100%;
        position: relative;
        border-bottom: 2px solid $clr-red-dark;
    }

    .divider-heart {
        color: $clr-red-dark;
        font-size: 140%;
        text-align: center;
        margin: 2*$mg-s;
        display: inline-block;
    }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}
