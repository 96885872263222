@import '../variables';

#striped-container {
    @extend %flex-col-center;

    max-width: 1000px;
    width: 90%;
    margin: 0 auto $mg-l;
    padding: 0 $pd-l;

    border-radius: 30px;
    border: 10px solid $clr-red-dark;
    background: repeating-linear-gradient(
            170deg,
            $clr-pink,
            $clr-pink 300px,
            $clr-pink-light 300px,
            $clr-pink-light 600px
    );

    h1 {
        transform: translateY(calc(-50% - 5px));
        padding: 3*$pd-s 2*$pd-m;
        margin-bottom: -70px; // counteract translation

        letter-spacing: -0.02em;
        text-align: center;
        font-weight: 600;
        font-size: 55px;

        color: $clr-pink-light;
        background-color: $clr-red-dark;
        clip-path: polygon(100% 0, 98% 50%, 100% 100%, 0% 100%, 2% 50%, 0% 0%);
    }

    h2 {
        text-decoration: underline;
        text-align: center;
        margin: 0 0 $mg-m;

        &:first-of-type {
            margin-top: 4*$mg-m;
        }
    }

    p {
        text-align: center;
        line-height: 1.5em;
    }

    .infoMail {
        margin-top: $mg-l;
        font-size: 75%;
        color: $clr-grey-dark;
        font-style: italic;
    }

    .optionsTxt {
        font-weight: 600;
        font-size: 110%;
    }

    #formButton {
        padding: 0 $pd-m $pd-l;

        a {
            background-color: rgba(white, 0.6);
            border: 2px solid $clr-red-ruby;
            border-radius: 15px;
            box-sizing: border-box;
            color: $clr-red-ruby;
            cursor: pointer;
            display: inline-block;

            min-height: 60px;
            min-width: 100px;

            outline: none;
            padding: 16px 24px;
            text-decoration: none;
            transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
        }

        a:hover, a:active {
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.25) 0 4px 8px;
            animation: pulse 200ms ease 0s 2;
        }
    }
}

@media only screen and (max-width: 1200px) {
    #striped-container {
        text-align: center;
    }
}

@media only screen and (max-width: 900px) {
    #striped-container {
        width: 95%;
        margin: 0 auto $mg-m;
        padding: 0 $pd-m;

        h1 {
            font-size: 140%;
        }

        h2 {
            font-size: 120%;
        }

        #formButton {
            margin-bottom: $mg-m;
        }
    }
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }
}
