@import '../variables';

header {
    @extend %flex-col-center;

    #logo{
        width: 500px;
        margin: $mg-l $mg-l 6*$mg-m;
    }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
    header #logo{
        width: 90%;
        margin: $mg-l $mg-l 3*$mg-m;
    }
}
